import React from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import Layout from "../components/layout";
import SEO from "../components/seo";
import { Section, BreadCrumb, SectionTitle, GradientWrapper } from "../components/Section";
import { ArrowRight, HomeIcon, ArrowLineIcon } from "../components/Icons";
import SecondaryLinkBtn from "../components/Button/SecondaryLinkButton";
import Image from "../components/image";
import BreakpointDown from "../components/Media/BreakpointDown";

const BlogContainer = styled.div`
  max-width: 890px;
  width: 100%;
  position: relative;
  margin-left: auto;
  margin-right: auto;
  padding-left: 15px;
  padding-right: 15px;
`;

const BlogWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`;
const Card = styled.div`
  display: flex;
  background: transparent;
  flex-direction: column;
  border-bottom: 1px solid #cbd1e2;
  padding-bottom: 25px;
  margin-bottom: 10px;
  ${BreakpointDown.md`
    padding-bottom:20px;
    margin-bottom:20px;
  `}
  &:last-child {
    border-bottom: 0;
    ${BreakpointDown.md`
    padding-bottom:0px;
    margin-bottom:20px;
  `}
  }
`;
const CardImg = styled.div`
  position: relative;
  max-width: 100%;
  width: 100%;
  border-radius: 4px;
  overflow: hidden;
  margin-top: 30px;
  margin-bottom: 30px;
`;

const BlogDate = styled.div`
  background: #223c7e;
  padding: 8px;
  position: absolute;
  left: 0;
  top: 30px;
  z-index: 4;
  color: #fff;
  font-size: 18px;
  max-width: 72px;
  line-height: 20px;
  text-align: center;
  padding: 4px;
  font-family: "Poppins", sans-serif;
`;
const CardBody = styled.div`
  margin-top: 0px;
  ${BreakpointDown.md`
  margin-top:15px; 
 `}
`;
const BlogInfo = styled.div`
  font-size: 16px;
  color: #121316;
  span {
    &:first-child {
      &:after {
        content: "";
        width: 1px;
        height: 12px;
        display: inline-block;
        vertical-align: middle;
        margin-left: 10px;
        margin-right: 3px;
        background: #121316;
      }
    }
  }
`;
const CardDescription = styled.div`
  margin-bottom: 10px;
`;
const CardTitle = styled.h4`
  line-height: 38px;
  margin-bottom: 5px;
`;
const CardBtn = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  a {
    &:first-child {
      .btn {
        transform: scaleX(-1);
        .text {
          transform: scaleX(-1);
        }
      }
    }
  }
`;

const BlogPage = () => (
  <Layout pageName="blog">
    <SEO title="Blog Detail" />
    <Section
      bgColor="rgba(203, 209, 226, 0.55)"
      pt="180px"
      xpt="60px"
      pb="20px"
    >
      <BreadCrumb top="75" xtop="15px">
        <div className="container">
          <Link to="/">
            <HomeIcon /> <ArrowRight /> Knowledge Hub <ArrowRight />
          </Link>
          <Link className="line-after-heading">
            Neque porro quisquam est qui..
          </Link>
          <SectionTitle mt="40px" mb="30px">
            Blog Detail
          </SectionTitle>
        </div>
      </BreadCrumb>
    </Section>
    <GradientWrapper>
      <Section
        pt="70px"
        pb="120px"
        xpt="40px"
        xpb="100px"
        mpt="30px"
        bgColor="transparent"
      >
        <BlogContainer>
          <BlogWrapper>
            <Card>
              <CardImg>
                <BlogDate>06 JAN 2021</BlogDate>
                <Image name="blog-banner.jpg" alt="Blog Banner" />
              </CardImg>
              <CardBody>
                <BlogInfo>
                  <span>Author By David</span>
                  <span>Posted In - Category Name</span>
                </BlogInfo>
                <CardTitle>
                  Neque porro quisquam est qui dolorem ips quia dolor sit amet,
                  consectetur
                </CardTitle>
                <CardDescription>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                    velit, sed quia non numquam eius modi tempora incidunt ut
                    labore et dolore magnam aliquam quaerat voluptatem.
                  </p>
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum deleniti atque
                    corrupti quos dolores et quas molestias excepturi sint
                    occaecati cupiditate non provident, similique sunt in culpa
                    qui officia deserunt mollitia animi, id est laborum et
                    dolorum fuga.
                  </p>
                  <blockquote>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum deleniti atque
                    corrupti quos dolores et quas molestias.
                  </blockquote>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <CardImg>
                    <Image name="blog-banner.jpg" alt="Blog Banner" />
                  </CardImg>
                  <p>
                    Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                    do eiusmod tempor incididunt ut labore et dolore magna
                    aliqua. Ut enim ad minim veniam, quis nostrud exercitation
                    ullamco laboris nisi ut aliquip ex ea commodo consequat.
                  </p>
                  <p>
                    Sed ut perspiciatis unde omnis iste natus error sit
                    voluptatem accusantium doloremque laudantium, totam rem
                    aperiam, eaque ipsa quae ab illo inventore veritatis et
                    quasi architecto beatae vitae dicta sunt explicabo. Nemo
                    enim ipsam voluptatem quia voluptas sit aspernatur aut odit
                    aut fugit, sed quia consequuntur magni dolores eos qui
                    ratione voluptatem sequi nesciunt. Neque porro quisquam est,
                    qui dolorem ipsum quia dolor sit amet, consectetur, adipisci
                    velit, sed quia non numquam eius modi tempora incidunt ut
                    labore et dolore magnam aliquam quaerat voluptatem.
                  </p>
                  <p>
                    At vero eos et accusamus et iusto odio dignissimos ducimus
                    qui blanditiis praesentium voluptatum deleniti atque
                    corrupti quos dolores et quas molestias excepturi sint
                    occaecati cupiditate non provident, similique sunt in culpa
                    qui officia deserunt mollitia animi, id est laborum et
                    dolorum fuga.
                  </p>
                </CardDescription>
              </CardBody>
            </Card>
            <CardBtn>
              <Link to="/">
                <SecondaryLinkBtn text="Prev Blog" icon={<ArrowLineIcon />} />
              </Link>
              <Link to="/">
                <SecondaryLinkBtn text="Next Blog" icon={<ArrowLineIcon />} />
              </Link>
            </CardBtn>
          </BlogWrapper>
        </BlogContainer>
      </Section>
    </GradientWrapper>
  </Layout>
);

export default BlogPage;
